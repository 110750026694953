import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Document } from "@contentful/rich-text-types"
import cn from "classnames"

import { richTextOptions, richTextOptionsSpecial } from "./Options"

import styles from "./styles.module.scss"

interface RichTextProps {
  document: Document
  className?: string
  isSpecial?: boolean
}

const SpecialRichText = ({
  document,
  className,
  isSpecial = false,
}: RichTextProps) => {
  const options = isSpecial ? richTextOptionsSpecial : richTextOptions
  return (
    <div className={cn(styles.section, className)}>
      {documentToReactComponents(document, options)}
    </div>
  )
}

export default SpecialRichText
