import { useMemo } from "react"
import { useRouter } from "next/router"
import { ICarsFields } from "@/types/contentful"
import { IPlaceCost } from "@/types/contentful"

const getPlaceCost = (
  placeCosts: ICarsFields["placeCost"],
  findPlaceId: string
) =>
  placeCosts.find((cost) =>
    cost.fields.place.fields.placeIds.find((placeId) => placeId === findPlaceId)
  )

const getFullPrice = (
  place: IPlaceCost,
  distance: number,
  pricePerMile: number
) => {
  const additionalDistance =
    Number(distance) - (place.fields.place.fields.includedMiles || 0)

  const additionalPrice =
    additionalDistance > 0 ? additionalDistance * pricePerMile : 0
  return additionalPrice + Number(place.fields.placeCost)
}

interface CarsPageItemProps
  extends Pick<ICarsFields, "placeCost" | "pricePerMile" | "pricePerHour"> {
  distance?: number | null
}

export const usePrice = ({
  distance,
  placeCost,
  pricePerMile,
  pricePerHour,
}: CarsPageItemProps) => {
  const router = useRouter()
  const price = useMemo(() => {
    if (!distance) {
      if (router.query.hours) {
        return (Number(router.query.hours) * Number(pricePerHour)).toFixed(2)
      } else {
        return null
      }
    }

    const fromPlace =
      router.query.from_place_id &&
      placeCost &&
      getPlaceCost(placeCost, router.query.from_place_id as string)
    const toPlace =
      router.query.to_place_id &&
      placeCost &&
      getPlaceCost(placeCost, router.query.to_place_id as string)

    if (fromPlace && !toPlace) {
      return getFullPrice(fromPlace, distance, Number(pricePerMile)).toFixed(2)
    }
    if (!fromPlace && toPlace) {
      return getFullPrice(toPlace, distance, Number(pricePerMile)).toFixed(2)
    }
    if (toPlace && fromPlace) {
      const expensivePath =
        Number(fromPlace.fields.placeCost) > Number(toPlace.fields.placeCost)
          ? fromPlace
          : toPlace

      return getFullPrice(
        expensivePath,
        distance,
        Number(pricePerMile)
      ).toFixed(2)
    }
    if (!fromPlace && !toPlace) {
      const minPath = placeCost.reduce((prev, curr) =>
        curr.fields.placeCost &&
        prev.fields.placeCost &&
        prev.fields.placeCost < curr.fields.placeCost
          ? prev
          : curr
      )
      return getFullPrice(minPath, distance, Number(pricePerMile)).toFixed(2)
    }
  }, [distance, placeCost, router.query, pricePerMile, pricePerHour])

  return price
}
