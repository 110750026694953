import { FunctionComponent } from "react"
import { Document } from "@contentful/rich-text-types"

import { ICars } from "@/types/contentful"
import SectionTitle from "../SectionTitle/SectionTitle"
import CarSectionCar from "../CarSectionCar/CarSectionCar"

import styles from "./styles.module.scss"
import RichText from "../RichText/RichText"

interface CarsSectionProps {
  cars: ICars[]
  sectionTitle?: Document
  sectionDescription?: Document
}

const CarsSection: FunctionComponent<CarsSectionProps> = ({
  sectionTitle,
  sectionDescription,
  cars,
}) => {
  return (
    <div id="cars">
      {(sectionTitle || sectionDescription) && (
        <div className={styles.cars__description}>
          {sectionTitle && (
            <SectionTitle className={styles.cars__header} variant="div">
              <RichText document={sectionTitle} />
            </SectionTitle>
          )}
          {sectionDescription && (
            <div className={styles.cars__header_text}>
              <RichText document={sectionDescription} />
            </div>
          )}
        </div>
      )}
      <div className={styles.cars__container}>
        {cars.map((car, index) => (
          <CarSectionCar {...car.fields} count={cars.length + 1} key={index} />
        ))}
      </div>
    </div>
  )
}

export default CarsSection
