import { FunctionComponent } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { A11y } from "swiper"
import cn from "classnames"

import { IReview } from "@/types/contentful"
import Star from "@/assets/icons/star.svg"

import styles from "./styles.module.scss"
import SectionTitle from "../SectionTitle/SectionTitle"
import SliderNavigation from "../SliderNavigation/SliderNavigation"

interface ReviewSectionProps {
  review: IReview[]
}

const Rates = [Star, Star, Star, Star, Star]

const ReviewSection: FunctionComponent<ReviewSectionProps> = ({ review }) => {
  return (
    <div className={styles.review}>
      <SectionTitle className={styles.review__header}>
        What our clients say?
      </SectionTitle>
      <Swiper className={styles.review__slider} modules={[A11y]}>
        {review.map((item, index) => (
          <SwiperSlide key={index}>
            <div className={styles.review__container}>
              <div className={styles.review__quotes}>“</div>
              <div className={styles.review__content}>
                <div>
                  {Rates.map((Rate, index) => (
                    <Rate
                      key={index}
                      className={cn(
                        item.fields.rate &&
                          item.fields.rate > index &&
                          styles["review__rate--filled"]
                      )}
                    />
                  ))}
                </div>
                <div className={styles.review__text}>{item.fields.review}</div>
                {item.fields.name && (
                  <div className={styles.review__name}>{item.fields.name}</div>
                )}
                {item.fields.date && (
                  <div className={styles.review__date}>
                    {new Date(item.fields.date).toISOString().split("T")[0]}
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
        {review && (
          <div className={styles.review__pagination}>
            <SliderNavigation varient="light" slides={review} />
          </div>
        )}
      </Swiper>
    </div>
  )
}

export default ReviewSection
