import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Document } from "@contentful/rich-text-types"

import richTextOptions from "./Options"

interface RichTextProps {
  document: Document
  className?: string
}

const RichText = ({ document, className }: RichTextProps) => {
  return (
    <div className={className}>
      {documentToReactComponents(document, richTextOptions)}
    </div>
  )
}

export default RichText
