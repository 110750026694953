import Link from "next/link"
import { Document } from "@contentful/rich-text-types"
import ReactPaginate from "react-paginate"
import { useRouter } from "next/router"
import Image from "next/image"

import SectionTitle from "../SectionTitle/SectionTitle"

import styles from "./styles.module.scss"
import RichText from "../RichText/RichText"
import SpecialRichText from "../SpecialRichText/SpecialRichText"
import { PAGINATION_LIMIT } from "@/constants/page"

export type SectionItemsProps = {
  items: any[]
  title: Document
  total?: number
  skip?: number
}

const SectionItems = ({ items, title, total, skip }: SectionItemsProps) => {
  const router = useRouter()
  const handlePageChange = (e: any) => {
    router.push(
      `/${router.query.slug?.[0]}/${e.selected > 0 ? e.selected : ""}`
    )
  }
  return (
    <>
      {title && (
        <SectionTitle className={styles.service__header} variant="div">
          <SpecialRichText document={title} isSpecial />
        </SectionTitle>
      )}
      {items && items.length > 0 && (
        <div className={styles.service}>
          {items.map((item, index: number) => (
            <div className={styles.service__item} key={index}>
              {(item.linkImageForListOfPages ||
                item.fields?.linkImageForListOfPages) && (
                <Link
                  href={item.slug || item.fields?.slug || "/"}
                  title={
                    item.linkImageForListOfPages?.fields.alt ||
                    item.fields.linkImageForListOfPages.fields.alt
                  }
                  className={styles.service__image_box}
                >
                  <Image
                    className={styles.service__image}
                    src={
                      item.linkImageForListOfPages?.fields.image.fields.file
                        .url ||
                      item.fields.linkImageForListOfPages.fields.image.fields
                        .file.url
                    }
                    alt={
                      item.linkImageForListOfPages?.fields.alt ||
                      item.fields.linkImageForListOfPages.fields.alt
                    }
                    width={450}
                    height={250}
                    loading="lazy"
                  />
                </Link>
              )}
              {(item.fields?.linkTitle || item.linkTitle) && (
                <div className={styles.service__title}>
                  <Link href={item.slug || item.fields?.slug || "/"}>
                    <RichText
                      document={item.linkTitle?.json || item.fields.linkTitle}
                    />
                  </Link>
                </div>
              )}
              {(item.fields?.linkDescription || item.linkDescription) && (
                <div className={styles.service__text}>
                  <RichText
                    document={
                      item.linkDescription?.json || item.fields.linkDescription
                    }
                  />
                </div>
              )}
              <Link
                className={styles.service__link}
                href={item.slug || item.fields?.slug || "/"}
                title={item.linkContent || item.fields?.linkContent}
              >
                {item.linkContent || item.fields?.linkContent}
              </Link>
            </div>
          ))}
        </div>
      )}
      {total !== undefined && skip !== undefined && (
        <div className={styles.pagination__container}>
          <ReactPaginate
            containerClassName={styles.pagination}
            pageClassName={styles.pagination__item}
            pageLinkClassName={styles.pagination__link}
            activeClassName={styles.pagination__link_active}
            previousLinkClassName={styles.pagination__link}
            nextLinkClassName={styles.pagination__link}
            disabledClassName={styles.pagination__link_disabled}
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageChange}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(total / PAGINATION_LIMIT)}
            initialPage={Math.ceil(skip / PAGINATION_LIMIT)}
            previousLabel="<"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  )
}

export default SectionItems
