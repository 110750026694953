import { FunctionComponent } from "react"

export interface SchemaSectionProps {
  schema: Object
}

const SchemaSection: FunctionComponent<SchemaSectionProps> = ({ schema }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  )
}

export default SchemaSection
