import { FunctionComponent } from "react"
import { IKeyFeaturesSectionFields } from "@/types/contentful/index"
import { Document } from "@contentful/rich-text-types"
import Image from "next/image"

import SectionTitle from "../SectionTitle/SectionTitle"
import SpecialRichText from "../SpecialRichText/SpecialRichText"
import RichText from "../RichText/RichText"

import styles from "./styles.module.scss"

export interface KeyFeatureSectionProp {
  features: IKeyFeaturesSectionFields["features"]
  title: Document
}

const KeyFeatureSection: FunctionComponent<KeyFeatureSectionProp> = ({
  features,
  title,
}) => {
  return (
    <div>
      {title && (
        <SectionTitle className={styles.feature__header} variant="div">
          <SpecialRichText document={title} isSpecial />
        </SectionTitle>
      )}
      <div className={styles.feature__wrapper}>
        {features?.map((feature, index) => (
          <div key={index} className={styles.feature__item}>
            {feature.fields.featureImage.fields.image.fields.file.url && (
              <Image
                src={feature.fields.featureImage.fields.image.fields.file.url}
                alt={feature.fields.featureImage.fields.alt}
                className={styles.feature__image}
                loading="lazy"
                width={700}
                height={196}
              />
            )}
            {feature.fields.title && (
              <div className={styles.feature__title}>
                <RichText document={feature.fields.title} />
              </div>
            )}
            {feature.fields.description && (
              <div className={styles.feature__description}>
                <RichText document={feature.fields.description} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default KeyFeatureSection
