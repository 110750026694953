import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Document } from "@contentful/rich-text-types"
import cn from "classnames"

import richTextOptions from "./Options"

import styles from "./styles.module.scss"

interface RichTextProps {
  document: Document
  className?: string
}

const SiteText = ({ document, className }: RichTextProps) => {
  return (
    <div className={cn(styles.section, className)}>
      {documentToReactComponents(document, richTextOptions)}
    </div>
  )
}

export default SiteText
