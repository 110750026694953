import dynamic from "next/dynamic"

import AboutSection from "@/components/AboutSection/AboutSection"
import Section from "@/components/Section/Section"
import MainSection from "../MainSection/MainSection"
import CarsSection from "../CarsSection/CarsSection"
import SectionItems from "../SectionItems/SectionItems"
import SiteText from "../SiteText/SiteText"
import FaqSection from "../FaqSection/FaqSection"
import ReviewSection from "../ReviewSection/ReviewSection"
import KeyFeatureSection from "../KeyFeatureSection/KeyFeatureSection"
import { ICars, IPageTemplate } from "@/types/contentful"
import CarsPageItem from "../CarsPageItem/CarsPageItem"
import SchemaSection from "../SchemaSection/SchemaSection"
import { ImageSection } from "../ImageSection/ImageSection"

import styles from "./styles.module.scss"

const VideoSection = dynamic(
  () => import("@/components/VideoSection/VideoSection"),
  { ssr: false }
)

const ContentPage = ({
  page,
  total,
  skip,
}: {
  page: IPageTemplate | ICars
  total?: number
  skip?: number
}) => {
  return (
    <>
      {page.fields.sections?.map((section: any, index: number) => {
        switch (section.sys.contentType.sys.id) {
          case "sliderWithForm":
            return <MainSection key={index} images={section.fields.images} />
          case "faq":
            return (
              <div key={index} className={styles.faq_section}>
                <Section id={section.fields.id}>
                  <FaqSection faq={section.fields.faq} />
                </Section>
              </div>
            )
          case "video":
            return (
              <VideoSection
                key={index}
                video={section.fields.video}
                id={section.fields.id}
              />
            )
          case "carsSection":
            return (
              <Section key={index}>
                <CarsSection
                  cars={section.fields.cars}
                  sectionTitle={section.fields.sectionTitle}
                  sectionDescription={section.fields.sectionDescription}
                />
              </Section>
            )
          case "siteText":
            return (
              <Section key={index} id={section.fields.id}>
                <SiteText document={section.fields.text} />
              </Section>
            )
          case "sectionItems":
            return (
              <Section key={index} id={section.fields.id}>
                <SectionItems
                  items={section.fields.links}
                  title={section.fields.title}
                />
              </Section>
            )
          case "sectionItemWithPagination":
            return (
              <Section key={index} id={section.fields.id}>
                <SectionItems
                  items={section.fields.links}
                  title={section.fields.title}
                  total={total}
                  skip={skip}
                />
              </Section>
            )
          case "reviewList":
            return (
              <Section key={index} id={section.fields.id}>
                <ReviewSection review={section.fields.review} />
              </Section>
            )
          case "pageHighlights":
            return (
              <Section
                key={index}
                className={styles.page}
                id={section.fields.id}
              >
                <AboutSection
                  title={section.fields.leftSide}
                  description={section.fields.rightSide}
                />
              </Section>
            )
          case "keyFeaturesSection":
            return (
              <Section key={index} id={section.fields.id}>
                <KeyFeatureSection
                  features={section.fields.features}
                  title={section.fields.sectionTitle}
                />
              </Section>
            )
          case "detailCarView":
            const pageData = page as ICars
            return (
              <Section key={index}>
                <CarsPageItem {...pageData.fields} />
              </Section>
            )
          case "schemaSection":
            return <SchemaSection key={index} schema={section.fields.schema} />
          case "imageSection":
            return (
              <Section key={index} id={section.fields.id}>
                <ImageSection {...section.fields} />
              </Section>
            )
          default:
            console.log(section.sys.contentType.sys.id)
            return <div key="todo"></div>
        }
      })}
    </>
  )
}

export default ContentPage
