import { useMemo, FunctionComponent } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import cn from "classnames"
import Image from "next/image"
import ReactGA from "react-ga4"

import UsersIcon from "@/assets/icons/users.svg"
import CaseIcon from "@/assets/icons/case.svg"
import Arrow from "@/assets/icons/arrow.svg"
import Button from "../forms/Button/Button"
import { ICarsFields } from "@/types/contentful"
import CarFeatures from "../CarFeatures/CarFeatures"
import { usePrice } from "@/hooks/usePrice"

import styles from "./styles.module.scss"

export interface CarsPageItemProps extends ICarsFields {
  distance?: number
  hours?: string
}

const CarsPageItem: FunctionComponent<CarsPageItemProps> = ({
  pricePerHour,
  pricePerMile,
  nameOfCar,
  image,
  passengers,
  luggage,
  features,
  distance,
  slug,
  hours,
  placeCost,
  brand,
  model,
  description,
}) => {
  const router = useRouter()
  const price = usePrice({ distance, placeCost, pricePerMile, pricePerHour })
  const href = useMemo(() => {
    const URL = hours || distance ? "/order/details" : "/order/location"
    return {
      pathname: URL,
      query: { ...router.query, slug, distance },
    }
  }, [router, hours, distance, slug])

  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: `${brand} ${model}`,
    description: description,
    brand: {
      "@type": "Brand",
      name: brand,
    },
    model: model,
    image: image.fields.file.url,
    offers: [
      {
        "@type": "Offer",
        priceSpecification: {
          "@type": "PriceSpecification",
          priceCurrency: "GBP",
          valueAddedTaxIncluded: true,
          pricePerUnit: "hour",
          price: pricePerHour,
        },
      },
    ],
    additionalProperty: [
      {
        "@type": "PropertyValue",
        name: "Passenger capacity",
        value: passengers,
      },
      {
        "@type": "PropertyValue",
        name: "Suitcase capacity",
        value: luggage,
      },
    ],
  }

  const handleSelectClick = () => {
    ReactGA.event("order_step_2_car_select", {
      type: hours ? "by_the_hour" : "one_way",
      car: nameOfCar,
    })
  }

  return (
    <div className={styles.cars__block}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
      <div className={styles.cars__block_container}>
        <h2 className={styles.cars__name}>{nameOfCar}</h2>
        <div className={styles.cars__image_wrapper}>
          <Image
            className={styles.cars__image}
            src={image?.fields.file.url}
            alt={image?.fields.title}
            width={485}
            height={275}
          />
        </div>
      </div>
      <div className={styles.cars__benefits}>
        <div className={styles.cars__benefits_content}>
          <h3 className={styles.cars__benefits_header}>
            This car accommodates:
          </h3>
          <p className={styles.cars__benefits_item}>
            <UsersIcon />
            <span>{passengers} passengers</span>
          </p>
          <p className={styles.cars__benefits_item}>
            <CaseIcon />
            <span>{luggage}</span>
          </p>
        </div>
        <CarFeatures features={features} />
      </div>
      <div className={styles.cars__block_price}>
        {!distance && !hours && (
          <>
            <p className={styles.cars__benefits_tax}>
              <span>&#163;</span>
              {pricePerMile}/mile
            </p>
            <p
              className={cn(
                styles.cars__benefits_tax,
                styles["cars__benefits_tax--last"]
              )}
            >
              <span>&#163;</span>
              {pricePerHour}/hour
            </p>
          </>
        )}
        {hours && Number(hours) > 0 && (
          <h3 className={styles.cars__benefits_full_payments}>
            <span>&#163;</span>
            {Number(hours) * Number(pricePerHour)}
          </h3>
        )}
        {Number(distance) > 0 && price && (
          <h3 className={styles.cars__benefits_full_payments}>
            <span>&#163;</span>
            {price}
          </h3>
        )}
        {hours && Number(hours) > 0 && (
          <p className={styles.cars__benefits_text}>
            <i>Each hours included 10 miles.</i>
          </p>
        )}
        <p className={styles.cars__benefits_text}>All fees are included.</p>
        <Link href={href} passHref legacyBehavior>
          <Button
            as="a"
            rightIcon={<Arrow className={styles.cars__arrow} />}
            className={styles.cars__button}
            onClick={handleSelectClick}
          >
            Select this car
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default CarsPageItem
