import { FunctionComponent } from "react"
import cn from "classnames"
import { Document } from "@contentful/rich-text-types"

import RichText from "@/components/RichText/RichText"

import styles from "./styles.module.scss"

interface AboutSectionProps {
  title: Document
  description: Document
  className?: string
}

const AboutSection: FunctionComponent<AboutSectionProps> = ({
  title,
  description,
  className,
}) => {
  return (
    <div className={cn(styles.about, className)}>
      {title && <RichText document={title} className={styles.about__header} />}
      {description && (
        <div className={styles.about__text}>
          <RichText document={description} />
        </div>
      )}
    </div>
  )
}

export default AboutSection
