import { useEffect, useState } from "react"
import { useSwiper } from "swiper/react"
import cn from "classnames"

import styles from "./styles.module.scss"

export const useSwiperReactive = () => {
  const swiper = useSwiper()

  const [, setSignal] = useState({})
  const forceRerender = () => setSignal({})

  useEffect(() => {
    if (swiper) swiper.on("slideChange", forceRerender)
  }, [swiper])

  return swiper
}

interface SliderNavigationProps<T> {
  varient?: "light" | "dark"
  slides: Array<T>
}

const SliderNavigation = <T,>({
  varient = "dark",
  slides,
}: SliderNavigationProps<T>) => {
  const swiper = useSwiperReactive()

  return (
    <div className={styles.pagination}>
      {slides.map((_, index) => {
        return (
          <button
            key={index}
            aria-label="Pagination button"
            onClick={() => swiper.slideTo(index)}
            className={cn(
              styles.pagination__item,
              swiper.activeIndex === index &&
                styles["pagination__item--active"],
              styles[`pagination__item--${varient}`]
            )}
          />
        )
      })}
    </div>
  )
}

export default SliderNavigation
