import { ICarsFields } from "@/types/contentful"
import { FunctionComponent } from "react"
import Image from "next/image"

import styles from "./styles.module.scss"

const CarFeatures: FunctionComponent<{ features: ICarsFields["features"] }> = ({
  features,
}) => {
  return (
    <div>
      <h3 className={styles.features__header}>We provide our clients with:</h3>
      {features?.map((feature, index) => (
        <div key={index} className={styles.features__benefits}>
          <Image
            src={feature.fields.file.url}
            alt={feature.fields.title}
            width={20}
            height={20}
          />
          <span>{feature.fields.title}</span>
        </div>
      ))}
    </div>
  )
}

export default CarFeatures
