import { ComponentProps } from "react"
import { Options } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import styles from "./styles.module.scss"

const richTextOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return <p className={styles.option__paragraph}>{children}</p>
    },
    [BLOCKS.HEADING_1]: (_, children) => {
      return <h1 className={styles.option__h1}>{children}</h1>
    },
    [BLOCKS.HEADING_2]: (_, children) => {
      return <h2 className={styles.option__h2}>{children}</h2>
    },
    [BLOCKS.HEADING_3]: (_, children) => {
      return <h3 className={styles.option__h3}>{children}</h3>
    },
    [BLOCKS.HEADING_4]: (_, children) => {
      return <h4 className={styles.option__h4}>{children}</h4>
    },
    [BLOCKS.HEADING_5]: (_, children) => {
      return <h5 className={styles.option__h5}>{children}</h5>
    },
    [BLOCKS.HEADING_6]: (_, children) => {
      return <h6 className={styles.option__h6}>{children}</h6>
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const props: ComponentProps<"a"> = {}
      if (node.data.uri.includes("http")) {
        props.rel = "nofollow"
      }
      if (children) {
        props.title = children as string
      }

      return (
        <a {...props} href={node.data.uri} className={styles.option__link}>
          {children}
        </a>
      )
    },
    [BLOCKS.UL_LIST]: (_, children) => {
      return <ul className={styles.option__ul}>{children}</ul>
    },
    [BLOCKS.OL_LIST]: (_, children) => {
      return <ol className={styles.option__ol}>{children}</ol>
    },
    [BLOCKS.LIST_ITEM]: (_, children) => {
      return <li className={styles.option__li}>{children}</li>
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <img
          className={styles.option__image}
          alt={node.data.target.fields.title}
          src={node.data.target.fields.file.url}
        />
      )
    },
    [BLOCKS.TABLE]: (_, children) => {
      return (
        <div className={styles.option__table_wrapper}>
          <table className={styles.option__table}>
            <tbody>{children}</tbody>
          </table>
        </div>
      )
    },
    [BLOCKS.TABLE_ROW]: (_, children) => {
      return <tr className={styles.option__tableRow}>{children}</tr>
    },
    [BLOCKS.TABLE_CELL]: (_, children) => {
      return <td className={styles.option__tableCell}>{children}</td>
    },
    [BLOCKS.TABLE_HEADER_CELL]: (_, children) => {
      return <th className={styles.option__tableHeaderCell}>{children}</th>
    },
  },
}

export default richTextOptions
