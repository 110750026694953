import { FunctionComponent, useState } from "react"
import cn from "classnames"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

import SectionTitle from "../SectionTitle/SectionTitle"
import RichText from "../RichText/RichText"
import DropdownIcon from "@/assets/icons/dropdown.svg"
import { IFaqItem } from "@/types/contentful"

import styles from "./styles.module.scss"

interface FaqSectionProps {
  faq: IFaqItem[]
}

const FaqSection: FunctionComponent<FaqSectionProps> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(0)

  const handleClick = (index: number) => {
    if (isOpen !== index) {
      setIsOpen(index)
    } else {
      setIsOpen(NaN)
    }
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faq.map((item) => {
      return {
        "@type": "Question",
        name: documentToPlainTextString(item.fields.title),
        acceptedAnswer: {
          "@type": "Answer",
          text: item.fields.description
            ? documentToPlainTextString(item.fields.description)
            : "",
        },
      }
    }),
  }

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
      <div className={styles.faq}>
        <SectionTitle className={styles.faq__title}>FAQ</SectionTitle>

        <ul className={styles.faq__list}>
          {faq.map((item: any, index: number) => {
            return (
              <li key={index} className={styles.faq__item}>
                <button
                  className={cn(
                    styles.faq__question,
                    isOpen === index && styles["faq__question--open"],
                    Number.isNaN(isOpen) && styles["faq__question--all_closed"]
                  )}
                  onClick={() => handleClick(index)}
                >
                  <RichText
                    document={item.fields.title}
                    className={cn(styles.faq__font, styles.faq__question_text)}
                  />
                  <DropdownIcon className={styles.faq__icon} />
                </button>

                <div className={styles.faq__answer}>
                  <RichText
                    document={item.fields.description}
                    className={cn(styles.faq__font, styles.faq__answer_text)}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default FaqSection
