import { A11y, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import cn from "classnames"
import Image from "next/image"

import { IImage } from "@/types/contentful/index"
import SliderNavigation from "../SliderNavigation/SliderNavigation"
import SectionTitle from "../SectionTitle/SectionTitle"

import styles from "./styles.module.scss"

import "swiper/swiper-bundle.min.css"

export interface MainSectionSliderProps {
  images: IImage[]
  className?: string
}

const MainSectionSlider = ({ images, className }: MainSectionSliderProps) => {
  return (
    <Swiper
      className={cn(styles.slider, className)}
      modules={[A11y, Autoplay]}
      autoplay={{ pauseOnMouseEnter: true }}
    >
      {images?.map((slide, index) => (
        <SwiperSlide key={index}>
          <div className={styles.slider__item}>
            <Image
              src={slide.fields.image.fields.file.url}
              alt={slide.fields.alt}
              className={styles.slider__image}
              loading="lazy"
              width={1202}
              height={970}
              priority
            />
            {slide.fields.slideTitle && (
              <SectionTitle
                variant={index ? "p" : "h1"}
                className={styles.slider__title}
              >
                {slide.fields.slideTitle}
              </SectionTitle>
            )}
          </div>
        </SwiperSlide>
      ))}
      {images && (
        <div className={styles.slider__pagination}>
          <SliderNavigation slides={images} />
        </div>
      )}
    </Swiper>
  )
}

export default MainSectionSlider
