import { FunctionComponent } from "react"
import { IImageSectionFields } from "@/types/contentful"
import SpecialRichText from "../SpecialRichText/SpecialRichText"

import styles from "./styles.module.scss"
import Link from "next/link"
import cn from "classnames"
import Button from "../forms/Button/Button"

export const ImageSection: FunctionComponent<IImageSectionFields> = ({
  image,
  text,
  imageLeftSide,
  linkContent,
  linkHref,
  linkTitle,
}) => {
  return (
    <div className={cn(styles.image, imageLeftSide && styles.image_left)}>
      {image && (
        <div className={styles.image__wrapper}>
          <img
            className={styles.image__image}
            src={image.fields.image.fields.file.url}
            alt={image.fields.alt}
            loading="lazy"
          />
        </div>
      )}
      <div className={styles.image__text}>
        <SpecialRichText document={text} className={styles.image__text} />
        {linkContent && linkHref && (
          <div className={styles.image__button}>
            <Link
              href={linkHref}
              passHref
              legacyBehavior
              title={linkTitle || linkContent}
            >
              <Button as="a">{linkContent}</Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
