import { useRouter } from "next/router"

export const useUrl = () => {
  const router = useRouter()
  const canonicalUrl = (
    `${process.env.NEXT_PUBLIC_SITE_URL}` +
    (router.asPath === "/" ? "" : router.asPath)
  )
    .split("#")[0]
    .split("?")[0]

  return canonicalUrl
}
